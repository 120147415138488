import Home from '../components/Home';
import InsuranceDeclarationDetails from '../components/insurance/InsuranceDeclarationDetails';
import VisitResponse from '../components/VisitResponse';
import Quote from '../components/marketplace/Quote';
import RequestQuote from '../components/marketplace/RequestQuote';

const Blank = () => "Blank";

const homeRoutes = {
    id: "Home",
    path: "/",
    component: Home
}

const quoteRoutes = {    

    path: "/quote",
    name: "Quote",
    component: Quote
};

// This route is not visible in the sidebar
const privateRoutes = {
    id: "Private",
    path: "/private",
    component: Blank,
    children: [
        {
            path: "/insurance-declaration/:identifier?",
            name: "InsuranceDeclarationDetails",
            component: InsuranceDeclarationDetails
        },
        {
            path: "/visitresponse/:identifier/:answer",
            name: "VisitResponse",
            component: VisitResponse
        },
        {
            path: "/requestquote",
            name: "RequestQuote",
            component: RequestQuote
        }
    ]
};

// routes with quote layout
export const quote = [    
    quoteRoutes
];

// routes with general layout layout
export const general = [
    homeRoutes,
    privateRoutes
];

