import React from "react";
import { connect } from "react-redux";
import CustomRadio from "./CustomRadio";
import * as types from "../../store/constants";
import {
    Typography,
    Box,
    RadioGroup,
    Grid,
    TextField
} from "@material-ui/core";

const QuoteChoices = ({ setQuoteOptions, quoteState, setEmployees }) => {

    const min = 1;
    const max = 150;    

    const trySetEmployees = (text) => {
        var value = parseInt(text, 10);

        if (value > max) value = max;
        if (value < min) value = min;

        setEmployees(value || "");
    }

    if (!quoteState.termOptions.length)
        return null;

    const gridWidth = 12 / quoteState.termOptions.length;    

    return (
        <React.Fragment>
            <Typography><strong>Duration</strong></Typography>
            <RadioGroup value={quoteState.quoteOptions.duration.toString()} onChange={e => setQuoteOptions({ duration: e.target.value })}>
                <Grid container spacing={3}>
                    {quoteState.termOptions.includes("1") && <Grid xs={gridWidth} item><CustomRadio main="1 Year" lower="5% Surcharge" value="1" selected={quoteState.quoteOptions.duration} /></Grid>}
                    {quoteState.termOptions.includes("3") && <Grid xs={gridWidth} item><CustomRadio main="3 Years" value="3" selected={quoteState.quoteOptions.duration} /></Grid>}
                    {quoteState.termOptions.includes("3") && <Grid xs={gridWidth} item><CustomRadio main="5 Years" lower="5% Discount" value="5" selected={quoteState.quoteOptions.duration} /></Grid>}
                </Grid>
            </RadioGroup>
            <Box mt={3}><Typography><strong>Number of Employees</strong></Typography></Box>
            <TextField
                size="small"
                type="number"
                variant="outlined"
                value={quoteState.quoteOptions.employees}
                InputProps={{ inputProps: { min: 1, max: 150 } }}
                onChange={(e) => trySetEmployees(e.target.value)}
            />
        </React.Fragment>
    )
};

const mapStateToProps = state => ({ quoteState: state.quoteReducer });

const mapDispatchToProps = dispatch => {
    return {
        setQuoteOptions: (info) => dispatch({ type: types.SET_QUOTE_OPTIONS, info }),
        setEmployees: employees => dispatch({ type: types.SET_QUOTE_EMPLOYEES, employees })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuoteChoices);
