import React, { useState } from "react";
import { connect } from "react-redux";
import styled, { createGlobalStyle } from "styled-components";
import Header from "./Header";
import Footer from "./Footer";
import { spacing } from "@material-ui/system";
import {
    CssBaseline,
    Paper as MuiPaper,
    withWidth
} from "@material-ui/core";

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${props => props.theme.body.background};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${props => props.theme.body.background};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const QuoteLayout = ({ children }) => {

    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <Root>
            <CssBaseline />
            <GlobalStyle />
            <AppContent>
                <Header onDrawerToggle={handleDrawerToggle} />
                <MainContent>
                    {children}
                </MainContent>
                <Footer />
            </AppContent>
        </Root>
    );
}

export default connect()(withWidth()(QuoteLayout));



