import * as React from 'react';
import * as types from './store/constants';
import { Alert, AlertTitle } from "@material-ui/lab";
import { connect } from 'react-redux';
import './custom.css'
import Routes from './routes/Routes';
import { StylesProvider } from '@material-ui/styles';
import { ThemeProvider } from 'styled-components';
import Helmet from 'react-helmet';
import maTheme from './theme';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { Snackbar } from "@material-ui/core";

const App = ({ snackState, removeSnack }) => {
    return (
        <React.Fragment>
            <Helmet
                titleTemplate='%s | WorkNest'
                defaultTitle='WorkNest'
            />
            <StylesProvider injectFirst>
                <MuiThemeProvider theme={maTheme[0]}>
                    <ThemeProvider theme={maTheme[0]}>
                        <Routes />
                        {snackState.snack.message && (
                            <Snackbar
                                style={{ height: "100%" }}
                                open={snackState.open}
                                autoHideDuration={snackState.snack.severity === "success" ? 6000 : null }
                                onClose={removeSnack}
                                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            >
                                <Alert
                                    onClose={removeSnack}
                                    variant={snackState.snack.severity === "error" ? "filled" : "standard" }
                                    severity={snackState.snack.severity}
                                >
                                    <AlertTitle>{snackState.snack.severity === "error" ? "Error" : null}</AlertTitle>
                                    {snackState.snack.message || "TEST"}
                                </Alert>
                            </Snackbar>
                        )}
                    </ThemeProvider>
                </MuiThemeProvider>                         
            </StylesProvider>
        </React.Fragment>
    );
}

const mapStateToProps = state => ({ snackState: state.snackReducer });

const mapDispatchToProps = dispatch => {
    return {
        removeSnack: () => dispatch({ type: types.REMOVE_SNACK })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

