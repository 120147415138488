export const REQUEST_ACCOUNT_DETAILS = 'REQUEST_ACCOUNT_DETAILS';
export const RECEIVE_ACCOUNT_DETAILS = 'RECEIVE_ACCOUNT_DETAILS';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const ADD_SITE = 'ADD_SITE';
export const DELETE_SITE = 'DELETE_SITE';
export const SAVE_CHANGES = "SAVE_CHANGES";
export const SAVED_CHANGES = "SAVED_CHANGES";
export const SHOW_CONTACTS = "SHOW_CONTACTS";
export const DELETE_CONTACT = "DELETE_CONTACT";
export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const ADD_CONTACT = "ADD_CONTACT";

export const REQUEST_DECLARATION = 'REQUEST_DECLARATION';
export const RECEIVE_DECLARATION = 'RECEIVE_DECLARATION';
export const UPDATE_DECLARATION = 'UPDATE_DECLARATION';
export const LEI_SAVE_CHANGES = 'LEI_SAVE_CHANGES';
export const LEI_SAVED_CHANGES = "LEI_SAVED_CHANGES";

export const SET_SNACK = 'SET_SNACK';
export const REMOVE_SNACK = 'REMOVE_SNACK';

export const SET_QUOTE = 'SET_QUOTE';
export const SET_QUOTE_INFO = 'SET_QUOTE_INFO';
export const SET_QUOTE_OPTIONS = 'SET_QUOTE_OPTIONS';
export const SET_QUOTE_FIELD = 'SET_QUOTE_FIELD';
export const SET_QUOTE_EMPLOYEES = 'SET_QUOTE_EMPLOYEES';
export const SET_QUOTE_PRODUCTS = 'SET_QUOTE_PRODUCTS';
