import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import {
    Typography,
    Box,
    Container,
    Button,
    Divider as MuiDivider,
    Link
} from '@material-ui/core'
import { spacing } from '@material-ui/system';
import styled from "styled-components";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import AddressSummary from './AddressSummary';
import { actionCreators } from '../../store/quoteReducer';
import { useHistory } from "react-router-dom";
import { bindActionCreators } from 'redux';

const Divider = styled(MuiDivider)(spacing);

const ConfirmComplete = ({ quoteState, completeOrder }) => {    

    const history = useHistory();

    const finishButtonUrl = useMemo(() => {
        let url = quoteState.finishButtonUrl;

        if (!url || quoteState.orderStatus ===1)
            return "https://worknest.com";

        url = url.replace("{{firstName}}", encodeURIComponent(quoteState.formInfo.mainContact.firstName));
        url = url.replace("{{lastName}}", encodeURIComponent(quoteState.formInfo.mainContact.lastName));
        url = url.replace("{{email}}", encodeURIComponent(quoteState.formInfo.mainContact.email));
        url = url.replace("{{phone}}", encodeURIComponent(quoteState.formInfo.mainContact.phone));
        url = url.replace("{{jobTitle}}", encodeURIComponent(quoteState.formInfo.mainContact.jobTitle || ""));
        url = url.replace("{{accountName}}", encodeURIComponent(quoteState.formInfo.accountName));

        return url;
    }, [quoteState]);

    const finishButtonText = useMemo(() => {
        if (!quoteState.finishButtonUrl || quoteState.orderStatus === 1)
            return "Return Home";

        return quoteState.finishButtonText;
    }, [quoteState]);

    return (
        <Container>
            <Box display="flex"  flexDirection="column" style={{ margin: '0 auto'}} gridGap="5px">
                <Box textAlign="center" display="flex" flexDirection="column" gridGap="15px" alignItems="center" justifyContent="center">
                    <CheckCircleOutlineIcon style={{ color: '#7a5cf0', backgroundColor: '#f2edff', padding: '15px', width: '60px', height: '60px', borderRadius: '30px' }} />
                    <Typography variant="h6">Thank you, {quoteState.formInfo.mainContact.firstName}</Typography>
                    {
                        quoteState.orderStatus > 1 &&
                        <Typography>Order Confirmed: {quoteState.name}</Typography>
                    }
                    {
                        quoteState.orderStatus === 1 && !quoteState.params.success && !quoteState.params.canceled &&
                        <Typography>It looks like we are awaiting payment confirmation.  Please refresh page to check status or click <Link onClick={() => completeOrder(history)}>here</Link> to try again.</Typography>
                    }
                    {
                        quoteState.orderStatus === 1 && quoteState.params.canceled &&
                        <Typography>It looks like you canceled the transaction.  Please click <Link onClick={() => completeOrder(history)}>here</Link> to try again.</Typography>
                    }
                    {
                        quoteState.orderStatus === 1 && quoteState.params.success &&
                        <Typography>It looks like we are awaiting payment confirmation.  Please refresh page to check status.</Typography>
                    }
                    {
                        quoteState.orderStatus > 1 &&
                        <Typography>You'll shortly receive a confirmation email containing your order details{quoteState.provideMyWorkNestAccess && " along with guidance on how you can access your account"}.</Typography>
                    }
                </Box>
                <Divider my={2} />
                <Box display="flex" flexDirection="column" gridGap="10px" flexGrow={1}>
                    <Typography variant="h6">Customer Information</Typography>
                    <Box>
                        <Typography style={{ fontWeight: 500, color: '#7a5cf0' }}>Contact Information</Typography>
                        <Typography>{quoteState.formInfo.mainContact.email}</Typography>
                    </Box>
                    <Box>
                        <Typography style={{ fontWeight: 500, color: '#7a5cf0' }}>Payment Method</Typography>
                        <Typography>{quoteState.paymentType}</Typography>
                    </Box>
                    <Box>
                        <Typography style={{ fontWeight: 500, color: '#7a5cf0' }}>Billing Address</Typography>
                        <AddressSummary address={quoteState.formInfo.mainAddress} />
                    </Box>
                </Box>
                <Button variant="contained" size="large" color="primary" href={finishButtonUrl}>{finishButtonText}</Button>
                <Box display="flex" justifyContent="center" gridGap="20px" alignItems="center">
                    <Typography>Need help?</Typography>
                    <a href="mailto:customerexperience@worknest.com">Contact us</a>
                </Box>                
            </Box>
        </Container>       
    );
}

const mapStateToProps = state => ({ quoteState: state.quoteReducer });

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(actionCreators, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmComplete);


