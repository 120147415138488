import { configureStore } from '@reduxjs/toolkit'
import declarationReducer from './declarationReducer';
import snackReducer from "./snackReducer";
import quoteReducer from "./quoteReducer";

export const store = configureStore({
    reducer: {
        declarationReducer,
        snackReducer,
        quoteReducer
    }
});

export default store;

