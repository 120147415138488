import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { actionCreators as declarationActionCreators } from '../../store/declarationReducer';
import { connect } from 'react-redux';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import InsuranceDeclarationForm from './InsuranceDeclarationForm';

const InsuranceDeclarationDetails = ({
    match,
    declarationState,
    updateDeclarationDetail,
    requestDeclarationDetails,
    saveChanges,
}) => {      

    const { identifier } = match.params;   

    useEffect(() => {
        requestDeclarationDetails(identifier);
    }, [identifier, requestDeclarationDetails]);

    return (
        <div style={{
            background: 'linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("images/Building 03.jpg") center center / cover no-repeat',
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            paddingTop: '12px'
        }}>
            <div className="scrollable">
                <div>
                    <Container>
                        {
                            declarationState.declaration && declarationState.isComplete === false && !declarationState.declaration.completed &&
                            <Row>
                                <Col className="mb-3">
                                    <Card>
                                        <Card.Body style={{ height: '100%' }}>
                                            <Card.Title>Annual Insurance Declaration</Card.Title>
                                            <Card.Subtitle className="mb-2 text-muted">{declarationState.declaration.account.accountName}</Card.Subtitle>
                                            <Card.Text>
                                                Please complete your annual insurance declaration using the form below. We will use the details below to ensure that appropriate cover is put in place for your business. If you have any problems completing this form, please let us know at <a href="mailto:cxsupport@worknest.com">cxsupport@worknest.com</a>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        }
                    </Container>
                </div>
                <div className="scrollable mb-3">
                    <Container>
                        <Row className="justify-content-md-center">
                            <Col className="mb-3" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                <Card>
                                    <Card.Body>
                                        {
                                            declarationState.isComplete === true &&
                                            <div>
                                                <Card.Text className="text-success">Submission Successful.</Card.Text>
                                                <Card.Text>
                                                    Thank you. Your Annual Insurance Declaration has been completed.
                                                </Card.Text>
                                            </div>
                                        }
                                        {
                                            declarationState.declaration && declarationState.isComplete === false && !declarationState.declaration.completed &&
                                            <InsuranceDeclarationForm
                                                declaration={declarationState.declaration}
                                                updateDeclarationDetail={updateDeclarationDetail}
                                                saveChanges={saveChanges}
                                            />
                                        }
                                        {
                                            declarationState.declaration === null && declarationState.isLoading === true &&
                                            <div className="text-center">
                                                <FontAwesomeIcon size="5x" icon={faSpinner} spin style={{ color: '#004b8d', marginBottom: '2em' }} />
                                                <Card.Text>Fetching your declaration form</Card.Text>
                                            </div>
                                        }
                                        {
                                            (declarationState.isSaving) &&
                                            <div className="text-center">
                                                <FontAwesomeIcon size="5x" icon={faSpinner} spin style={{ color: '#004b8d', marginBottom: '2em' }} />
                                                <Card.Text>Saving your details</Card.Text>
                                            </div>
                                        }
                                        {
                                            declarationState.declaration && declarationState.declaration.completed &&
                                            <Card.Text>Declaration has already been completed.</Card.Text>
                                        }
                                        {
                                            typeof (declarationState.declaration) != "object" && declarationState.isLoading === false &&
                                            <Card.Text>Declaration does not exist or has already been completed.</Card.Text>
                                        }
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({ declarationState: state.declarationReducer });

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(declarationActionCreators, dispatch),        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InsuranceDeclarationDetails);
