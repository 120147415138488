import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { SET_QUOTE_FIELD } from '../../store/constants';

import {
    TextField,
    Box,
    Grid
} from '@material-ui/core';

const ContactFields = ({ id, quoteState, setFormInfo }) => {

    const contact = quoteState.formInfo[id];

    const validEmail = email => {
        var valid = /^[^.](?!.*\.\.)([\w+'.-]+)(?<!\.)@([\w.-]+)[^.]$/.test(email);
        return valid;
    }

    useEffect(() => {
        console.log('contact use effect');
        setFormInfo({
            [id]: {
                ...contact,
                isValid: (contact.firstName?.trim() && contact.lastName?.trim() && validEmail(contact.email) && contact.phone?.trim() && contact.jobTitle?.trim()) ? true : false
            }    
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contact.firstName, contact.lastName, contact.email, contact.phone, id, setFormInfo, contact.jobTitle]);
  
    const setField = e => {

        let updated = {
            ...contact,
            [e.target.name]: e.target.value
        };        
        
        setFormInfo({
            [id]: updated
        });
    }
   
    return (
        <Box display="flex" flexDirection="column" gridGap="10px">
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        style={{ width: '100%' }}
                        label="First Name"
                        variant="outlined"
                        name="firstName"
                        size="small"
                        value={contact.firstName || ""}
                        onChange={setField}
                        error={!contact.firstName?.trim()}
                        inputProps={{ maxLength: 40 }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        style={{ width: '100%' }}
                        label="Last Name"
                        variant="outlined"
                        name="lastName"
                        size="small"
                        value={contact.lastName || ""}
                        onChange={setField}
                        error={!contact.lastName?.trim()}
                        inputProps={{ maxLength: 80 }}
                    />
                </Grid>
            </Grid>
            <TextField
                label="Email"
                variant="outlined"
                size="small"
                type="email"
                name="email"
                value={contact.email || ""}
                onChange={setField}
                error={!validEmail(contact.email)}
                inputProps={{ maxLength: 80 }}
            />
            <TextField
                value={contact.phone || ""}
                label="Telephone"
                type="tel"
                name="phone"
                variant="outlined"
                size="small"
                onChange={setField}
                error={!contact.phone?.trim()}
                inputProps={{ maxLength: 40 }}
            />
            <TextField
                value={contact.jobTitle || ""}
                label="Job Title"
                name="jobTitle"
                variant="outlined"
                size="small"
                onChange={setField}
                error={!contact.jobTitle?.trim()}
                inputProps={{ maxLength: 128 }}
            />
        </Box>
    );
}
const mapStateToProps = state => ({ quoteState: state.quoteReducer });

const mapDispatchToProps = dispatch => {
    return {
        setFormInfo: (info) => dispatch({ type: SET_QUOTE_FIELD, info })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactFields);

