import React from "react";
import styled from "styled-components";

import {
    Link,
    Box
} from "@material-ui/core";

const Wrapper = styled.div`
  padding: 10px;
  position: relative;
`;

const Footer = () => {
    return (
        <Wrapper>
            <Box display="flex" justifyContent="space-around">
                <Box>© {new Date().getFullYear()} - WorkNest</Box>
                <Link target="_blank" href="https://worknest.com/privacy-policy/">Privacy Policy</Link>
            </Box>
        </Wrapper>
    );
}

export default Footer;