import React, { useState, useEffect } from 'react';
import { Form, Button, Col } from 'react-bootstrap';
import AddressBar from './AddressBar';
import moment from 'moment';

const InsuranceDeclarationForm = ({
    declaration,
    updateDeclarationDetail,
    saveChanges
}) => {
    const [validated, setValidated] = useState(false);
    const [state, setState] = useState({
        name: "",
        email: "",
        position: "",
        numEmployees: "",
        activityChecked: null,
        businessActivityChanged: "",
        circumstanceChecked: null,
        circumstanceChanged: "",
        associatedBusinesses: "",
        comments: "",
    });

    const handleInputChange = e => {
        const name = e.target.name;
        const value = e.target.value;
        setState(s => ({ ...s, [name]: value }));
    }

    const handleSubmit = e => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
        }
        else {            
            updateDeclarationDetail({
                ...declaration,
                completedBy: state.name + "," + state.email + "," + state.position,
                numEmployees: parseFloat(state.numEmployees),
                businessActivityChanged: state.businessActivityChanged,
                circumstanceChanged: state.circumstanceChanged,
                comments: state.comments,
                associatedBusinesses: state.associatedBusinesses,
                toSave: true
            })
        }
        setValidated(true);
    }

    useEffect(() => {
        declaration && setState(s => ({
            ...s,
            name: (declaration.completedBy && declaration.completedBy.split(",")[0]) || "",
            email: (declaration.completedBy && declaration.completedBy.split(",")[1]) || "",
            position: (declaration.completedBy && declaration.completedBy.split(",")[2]) || "",
            numEmployees: declaration.numEmployees || "",
            businessActivityChanged: declaration.businessActivityChanged || "",
            circumstanceChanged: declaration.circumstanceChanged || "",
            comments: declaration.comments || "",
            circumstanceChecked: declaration.circumstanceChanged ? true : null,
            activityChecked: declaration.businessActivityChanged ? true : null,
            associatedBusinesses: declaration.associatedBusinesses || "",
        }))
    }, [declaration]);    

    useEffect(() => {
        if (declaration.toSave) {
            saveChanges();
        }
    }, [declaration, saveChanges]);

    return (
        <Form noValidate validated={validated} style={{ padding: '5px', width: '100%' }} onSubmit={handleSubmit} >
            <Form.Row>
                <Col md="6">
                    <Form.Group>
                        {
                            declaration.prevDeclaration &&
                            <Form.Text className="text-muted" style={{ fontStyle: 'italic', backgroundColor: 'rgb(233,236,239)', borderRadius: '5px', paddingLeft: '5px' }}>
                                * Denotes answer for previous year's LEI declaration {declaration.prevDeclaration.endDate ? (" expiring " + moment(declaration.prevDeclaration.endDate).format("DD/MM/YYYY")) : ""}
                            </Form.Text>
                        }
                        <Form.Label>Your Name</Form.Label>
                        <Form.Control placeholder="Your Full Name" required name="name" value={state.name} onChange={handleInputChange} />
                        <Form.Control.Feedback type="invalid">Please provide your full name</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Company Name</Form.Label>
                        <Form.Control
                            placeholder="Company Name"
                            disabled
                            name="accountName"
                            value={declaration.account.accountName}
                        />
                        <Form.Text className="text-muted">To notify us of a change to the Company Name, please include this information in the 'Additional Comments' box below.</Form.Text>
                        <Form.Control.Feedback type="invalid">Please provide your company name</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Your Email Address</Form.Label>
                        <Form.Control placeholder="Your Email Address" type="email" required name="email" value={state.email} onChange={handleInputChange} />
                        <Form.Text className="text-muted">We'll use this to send you a confirmation and for our records</Form.Text>
                        <Form.Control.Feedback type="invalid">Please provide a valid email address</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Your Position</Form.Label>
                        <Form.Control placeholder="Your Position" required name="position" value={state.position} onChange={handleInputChange} />
                        <Form.Control.Feedback type="invalid">
                            Please provide your position
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Employee Numbers</Form.Label>
                        <Form.Control placeholder="Employee Numbers" required type="number" step="1" name="numEmployees" value={state.numEmployees} onChange={handleInputChange} />
                        {
                            declaration.prevDeclaration && declaration.prevDeclaration.numEmployees
                                ? <Form.Text className="text-muted" style={{ fontStyle: 'italic', backgroundColor: 'rgb(233,236,239)', borderRadius: '5px', paddingLeft: '5px' }}>
                                    * {declaration.prevDeclaration.numEmployees.toLocaleString()}
                                </Form.Text>
                                : <div></div>
                        }
                        <Form.Text className="text-muted">This is the total number of employees with an employment contract who are to be covered by this policy</Form.Text>
                        <Form.Control.Feedback type="invalid">Please provide your employee numbers</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Main Business</Form.Label>
                        <AddressBar site={declaration.account} />         
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Associated Businesses</Form.Label>
                        <Form.Control as="textarea" rows={6} placeholder="Associated Businesses" name="associatedBusinesses" value={state.associatedBusinesses} onChange={handleInputChange} />
                        <Form.Text className="text-muted">List of sites covered under this policy</Form.Text>                        
                    </Form.Group>  
                </Col>
                <Col md="6">
                    <Form.Group>
                        <Form.Label>Business Activity Change</Form.Label>
                        <Form.Text className="text-muted">Has your business activity changed, or is it likely to change from that previously advised to WorkNest?</Form.Text>
                        <Form.Check inline>
                            <Form.Check.Input
                                required={state.activityChecked === null}
                                checked={state.activityChecked === false}
                                onChange={() => setState(prevState => ({ ...prevState, activityChecked: false }))}
                            />
                            <Form.Check.Label>No</Form.Check.Label>
                        </Form.Check>
                        <Form.Check inline>
                            <Form.Check.Input
                                required={state.activityChecked === null}
                                checked={state.activityChecked === true}
                                onChange={() => setState(prevState => ({ ...prevState, activityChecked: true }))}
                            />
                            <Form.Check.Label>Yes</Form.Check.Label>
                        </Form.Check>
                        {
                            state.activityChecked &&
                            <React.Fragment>
                                <Form.Text className="text-muted">
                                    Please provide further details
                                </Form.Text>
                                <Form.Control
                                    placeholder="Business Activity Changed"
                                    required={state.activityChecked}
                                    value={state.businessActivityChanged}
                                    name="businessActivityChanged"
                                    onChange={handleInputChange}
                                />
                            </React.Fragment>
                        }
                        {
                            declaration.prevDeclaration && declaration.prevDeclaration.businessActivityChanged
                                ? <Form.Text className="text-muted" style={{ fontStyle: 'italic', backgroundColor: 'rgb(233,236,239)', borderRadius: '5px', paddingLeft: '5px' }}>
                                    * {declaration.prevDeclaration.businessActivityChanged}
                                </Form.Text>
                                : <div></div>
                        }
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Change in Circumstances</Form.Label>
                        <Form.Text className="text-muted">
                            Other than matters you have already advised us about are there any causes, events or circumstances which may give rise to a claim being made under the insurance?
                        </Form.Text>
                        <Form.Check inline>
                            <Form.Check.Input
                                required={state.circumstanceChecked === null}
                                checked={state.circumstanceChecked === false}
                                onChange={() => setState(prevState => ({ ...prevState, circumstanceChecked: false }))}
                            />
                            <Form.Check.Label>No</Form.Check.Label>
                        </Form.Check>
                        <Form.Check inline>
                            <Form.Check.Input
                                required={state.circumstanceChecked === null}
                                checked={state.circumstanceChecked === true}
                                onChange={() => setState(prevState => ({ ...prevState, circumstanceChecked: true }))}
                            />
                            <Form.Check.Label>Yes</Form.Check.Label>
                        </Form.Check>
                        {
                            state.circumstanceChecked &&
                            <React.Fragment>
                                <Form.Text className="text-muted">
                                    Please provide further details
                                </Form.Text>
                                <Form.Control placeholder="Circumstance Changed" required={state.circumstanceChecked} name="circumstanceChanged" value={state.circumstanceChanged} onChange={handleInputChange} />
                            </React.Fragment>
                        }
                        {
                            declaration.prevDeclaration && declaration.prevDeclaration.circumstanceChanged
                                ? <Form.Text className="text-muted" style={{ fontStyle: 'italic', backgroundColor: 'rgb(233,236,239)', borderRadius: '5px', paddingLeft: '5px' }}>
                                    * {declaration.prevDeclaration.circumstanceChanged}
                                </Form.Text>
                                : <div></div>
                        }
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Additional Comments</Form.Label>
                        <Form.Control as="textarea" placeholder="Additional Comments" name="comments" value={state.comments} onChange={handleInputChange} />
                        {
                            declaration.prevDeclaration && declaration.prevDeclaration.comments
                                ? <Form.Text className="text-muted" style={{ fontStyle: 'italic', backgroundColor: 'rgb(233,236,239)', borderRadius: '5px', paddingLeft: '5px' }}>
                                    * {declaration.prevDeclaration.comments}
                                </Form.Text>
                                : <div></div>
                        }
                    </Form.Group>
                    <Form.Group>
                        <Form.Check custom required id="confirmCheck" type="checkbox" label="I confirm this information is correct at the time of completion" />
                    </Form.Group>
                    <div className="text-center">
                        <Button type="submit">Complete Annual Declaration</Button>
                    </div>
                </Col>
            </Form.Row>
        </Form>
    );
}

export default InsuranceDeclarationForm;