import axios from "../plugins/axios";
import * as types from "./constants";

const initialState = {
    isLoading: false,
    isInvalid: false,
    isSaving: false,
    isSaved: false,
    params: null,
    declarationAgreedDate: null,
    paymentType: "Direct Debit",
    isMainELContact: true,
    differentBillingAddress: false,
    isOrdering: false,
    orderStatus: null,
    stage: "quote",
    subStage: null,
    formInfo: {},
    name: "",
    partnerName: null,
    identifier: "",
    template: "",
    products: [],
    quoteOptions: null,
    discount: null,
    salesInfo: null,
    paymentOptions: [],
    termOptions: []
};

const apiUrl = process.env.REACT_APP_FORM_API_URL;

export const actionCreators = {
    getQuote: (urlSearchParams) => async (dispatch, getState) => {       

        let params = {};

        for (const param of urlSearchParams) {
            params = { ...params, [param[0]]: param[1] };
        }

        console.log("params", params);

        dispatch({
            type: types.SET_QUOTE_INFO,
            info: { params, isLoading: true },
        });

        let response = await axios.get(`${apiUrl}/getquote?${urlSearchParams}`);
        let info = response.data;

        if (info === "") {
            dispatch({ type: types.SET_QUOTE_INFO, info: { isInvalid: true } });
        } else {
            dispatch({ type: types.SET_QUOTE, info });
        }        
    },

    saveQuote: () => async (dispatch, getState) => {
        dispatch({ type: types.SET_QUOTE_INFO, info: { isSaving: true } });

        let quoteState = getState().quoteReducer;      

        var info = {
            ...quoteState.quoteOptions,
            productCodes: quoteState.products
                .filter((x) => x.selected)
                .map((x) => x.productCode)
        }

        let response = await axios.post(`${apiUrl}/savequote`, info);

        dispatch({
            type: types.SET_QUOTE_INFO,
            info: {
                isSaving: false,
                isSaved: true,
                name: quoteState.name || response.data,
            },
        });
    },

    completeOrder: (history) => async (dispatch, getState) => {
        dispatch({ type: types.SET_QUOTE_INFO, info: { isOrdering: true } });

        let quoteState = getState().quoteReducer;

        let formInfo = { ...quoteState.formInfo };

        if (quoteState.differentBillingAddress)
            formInfo.billingAddress = null;

        let info = {
            identifier: quoteState.identifier,
            template: quoteState.template,
            declarationAgreedDate: quoteState.declarationAgreedDate,
            quoteOptions: {
                ...quoteState.quoteOptions,
                productCodes: quoteState.products
                    .filter((x) => x.selected)
                    .map((x) => x.productCode)
            },
            paymentType: quoteState.paymentType,
            differentBillingAddress: quoteState.differentBillingAddress,
            isMainELContact: quoteState.isMainELContact,
            ...formInfo,            
        };

        let response = await axios.post(`${apiUrl}/completeOrder`, info);

        if (response.data.paymentUrl) {
            window.location.href = response.data.paymentUrl;
        }
        else {
            dispatch({
                type: types.SET_QUOTE_INFO,
                info: {
                    isOrdering: false,
                    stage: "complete",
                    name: response.data.name,
                    identifier: response.data.identifier
                },
            });

            history.replace(`/quote?identifier=${response.data.identifier}`);
        }       
    }
};

const getPrice = (product, employeeCount) => {
    if (product.pricingMethod === "List")
        return product.unitPrice;

    employeeCount = employeeCount || 1;
    
    var bp = product.blockPrices.find(
        (bp) => bp.lowerBound <= employeeCount && bp.upperBound > employeeCount
    );  

    return bp && bp.price;
};

const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === types.SET_QUOTE) {
        let info = action.info;        
        let params = state.params;

        return {
            ...state,
            name: info.name,
            orderStatus: info.orderStatus,
            partnerName: info.partnerName,
            finishButtonText: info.finishButtonText,
            finishButtonUrl: info.finishButtonUrl,
            provideMyWorkNestAccess: info.provideMyWorkNestAccess,
            stage: info.orderStatus > 0 ? "complete" : state.stage,
            message: info.message,
            template: info.template,
            identifier: info.identifier,
            products: info.products?.map(p => ({
                ...p,
                price: getPrice(p, info.quantity),
            })),
            quoteOptions: info.quoteOptions,
            discount: info.discount,
            salesInfo: info.salesInfo,
            paymentType: info.paymentType || info.paymentOptions[0],
            paymentOptions: info.paymentOptions,
            termOptions: info.termOptions,
            isLoading: false,
            formInfo: {
                accountName: info.accountName || params.company,
                registrationNumber: info.registrationNumber,
                mainAddress: info.mainAddress || ({
                    addressLine1: params.address_one,
                    city: params.city,
                    postCode: params.zip,
                }),
                billingAddress: {},
                mainContact: info.mainContact || ({
                    firstName: params.first_name,
                    lastName: params.last_name,
                    email: params.email,
                    phone: params.phone,
                }),
                elContact: {},
            }
        };
    }

    if (action.type === types.SET_QUOTE_INFO) {
        return {
            ...state,
            isLoading: false,
            ...action.info,
        };
    }

    if (action.type === types.SET_QUOTE_OPTIONS) {
        return {
            ...state,
            quoteOptions: {
                ...state.quoteOptions,
                ...action.info,
            },
        };
    }

    if (action.type === types.SET_QUOTE_FIELD) {

        return {
            ...state,
            formInfo: {
                ...state.formInfo,
                ...action.info,
            }
        };
    }

    if (action.type === types.SET_QUOTE_EMPLOYEES) {
        return {
            ...state,
            quoteOptions: {
                ...state.quoteOptions,
                employees: action.employees,
            },
            products: state.products.map(p => ({
                ...p,
                price: getPrice(p, action.employees),
            })),
        };
    }

    if (action.type === types.SET_QUOTE_PRODUCTS) {
        return {
            ...state,
            products: action.products,
        };
    }

    return state;
};

export default reducer;
