import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import {
    Typography,
    Box,
    FormControlLabel,
    Radio
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
    radioYes: {
        border: '2px solid #5a43c6',
        backgroundColor: '#f9f8fd',
        borderRadius: '4px',
        padding: '8px 10px'
    },
    radioNo: {
        border: '1px solid #757575',
        borderRadius: '4px',
        padding: '9px 11px',
        color: '#757575'
    }
}));

const RadioControlLabel = withStyles({
    root: {
        width: '100%',
        margin: 0,
        "& .MuiFormControlLabel-label": {
            width: '100%'
        }
    }
})(FormControlLabel)

const CustomRadio = ({ main, right, lower, value, selected, lowerRight }) => {
    const classes = useStyles();

    let label = (
        <Box display="flex" alignItems="center" flexDirection="row" >
            <Box flexGrow={1} display="flex" flexDirection="column">
                <strong>{main}</strong>
                {lower && <Typography style={{ fontSize: '0.7em' }}>{lower}</Typography>}
            </Box>
            <Box display="flex" alignItems="flex-end" flexDirection="column">
                <Typography>{right}</Typography>
                {lowerRight && <Box><Typography style={{ fontSize: '0.7em', color: '#5c45c7' }}>{lowerRight}</Typography></Box>}
            </Box>
        </Box>);

    return (
        <Box display="flex" className={value === selected ? classes.radioYes : classes.radioNo} alignItems="center" flexDirection="row">
            <RadioControlLabel control={<Radio color="primary" value={value} />} label={label} />
        </Box>
    );
}

export default CustomRadio;
