import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    Typography,
    Box,
    Divider as MuiDivider,
    Accordion as MuiAccordion,
    AccordionSummary as MuiAccordionSummary,
    AccordionDetails,
    Hidden
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    accordion: {
        background: 'transparent'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    expanded: {
        transform: 'rotate(180deg)'
    }
}));

const Accordion = withStyles({
    root: {
        borderBottom: '1px solid #b5b9cd',
        boxShadow: 'none',
        '&$expanded': {
            margin: '0 auto',
        },
        '&:before': {
            display: 'none',
        },
        width: '100%'
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#f3f3f6',
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const Divider = styled(MuiDivider)(spacing);

const QuoteSummary = ({ quoteState }) => {

    const [expanded, setExpanded] = useState(false);
    const classes = useStyles();

    const selectedProducts = quoteState.products.filter(p => p.selected === true);
    const years = parseInt(quoteState.quoteOptions.duration);
    const discounts = [];

    for (const product of selectedProducts) {
        if (years === 5 && product.hasFiveYearDiscount)
            discounts.push({ info: '5 year discount (5%)', amount: product.price * 0.05, productCode: product.productCode });

        if(years === 1 && product.hasFiveYearDiscount)
            discounts.push({ info: '1 year surcharge (5%)', amount: product.price * -0.05, productCode: product.productCode });

        if (product.free)
            discounts.push({ info: `${product.name} - Free for the duration of your contract`, amount: product.price, productCode: product.productCode });

        if (product.yearOneFree)
            discounts.push({ info: `${product.name} - First year free`, amount: product.price, yearOneFree: true, productCode: product.productCode });
    }

    const yearlyCost = selectedProducts.filter(p => p.pricingMethod === "Block").map(i => i.price).reduce((a, b) => a + b, 0);
    const oneOffCost = selectedProducts.filter(p => p.pricingMethod === "List").map(i => i.price).reduce((a, b) => a + b, 0);

    const total = oneOffCost + yearlyCost - discounts.map(i => i.amount).reduce((a, b) => a + b, 0);

    const Summary = () => (
        <Box display="flex" flexDirection="column" className={classes.root}>
            <Box mt={2} mb={1}><Typography><strong>Products</strong></Typography></Box>
            {
                selectedProducts.map(product =>
                    <Box display="flex" flexDirection="row" key={product.productCode} style={{ marginBottom: '5px' }}>
                        <Box flexGrow={1}><Typography>{product.name}</Typography></Box>
                        <Typography><strong>&pound;{product.price?.toFixed(2)}</strong>{product.pricingMethod !== "List" && "/year"}</Typography>
                    </Box>
                )
            }
            <Box display="flex" flexDirection="row" mt={2}>
                <Box flexGrow={1}><Typography><strong>Subtotal</strong></Typography></Box>
                {yearlyCost > 0 && <Typography><strong>&pound;{yearlyCost.toFixed(2)}</strong>/year</Typography>}
                {oneOffCost > 0 && <Typography><strong>&pound;{oneOffCost.toFixed(2)}</strong></Typography>}
            </Box>
            {
                discounts.length > 0 &&
                <React.Fragment>
                    <Divider my={2} />
                    <Box mb={1}><Typography><strong>Discount</strong></Typography></Box>
                </React.Fragment>
            }
            {
                discounts.map(d =>
                    <Box display="flex" flexDirection="row" style={{ marginBottom: '5px' }} key={d.productCode}>
                        <Box flexGrow={1}><Typography>{d.info}</Typography></Box>
                        <Typography><strong>-&pound;{d.amount?.toFixed(2)}</strong>{!d.yearOneFree && "/year"}</Typography>
                    </Box>
                )
            }
            <Divider my={2} />
            <Box display="flex" flexDirection="row">
                <Box flexGrow={1}><Typography>{quoteState.orderStatus > 1 ? "Paid" : "To Pay Today"}</Typography></Box>
                <Typography><strong>&pound;{total.toFixed(2)}</strong> (exc. VAT)</Typography>
            </Box>
        </Box>
    );
    return (
        <React.Fragment>
            <Hidden smUp>
                <Accordion className={classes.accordion} expanded={expanded} onChange={(e, isExpanded) => setExpanded(isExpanded)}>
                    <AccordionSummary className={classes.accordion}>
                        <div style={{ width: '135px' }}><Typography>{expanded ? "Hide" : "Show"} order summary</Typography></div>
                        <ExpandMoreIcon className={expanded ? classes.expanded : null} />
                        <Box flexGrow={1} />
                        <Typography><strong>&pound;{total.toFixed(2)}</strong> (exc. VAT)</Typography>                        
                    </AccordionSummary>
                    <AccordionDetails>
                        <Summary style={{ background: '#eeedf6' }} />
                    </AccordionDetails>
                </Accordion>
            </Hidden>
            <Hidden only="xs">
                <Box><Typography variant="h5"><strong>Order Summary</strong></Typography></Box>
                <Summary style={{ background: 'white' }} />
            </Hidden>
        </React.Fragment>
    );
}

const mapStateToProps = state => ({ quoteState: state.quoteReducer });

export default connect(mapStateToProps)(QuoteSummary);