import React from "react";
import workNestStyles from "../../theme/worknest";
import {
    Typography,
    Box,
    Paper
} from "@material-ui/core";

const HaveQuestion = () => {
    const classes = workNestStyles();
    return (
        <Paper className={classes.panel} style={{ textAlign: 'center' }}>
            <Box><Typography gutterBottom variant="h5">Have a question?</Typography></Box>
            <Box><Typography>Email our team now at <a href="mailto:enquiries@worknest.com">enquiries@worknest.com</a></Typography></Box>
        </Paper>
    )
};

export default HaveQuestion;