import React from 'react';
import { Container } from 'reactstrap';

export default props => (
    <div style={{height:"100vh"}}>       
        <Container fluid={true} className="fullHeight">
            {props.children}
        </Container>
    </div>
);
