import React from 'react';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/quoteReducer';
import { bindActionCreators } from 'redux';
import { makeStyles } from '@material-ui/core/styles';
import ExpandableRadio from './ExpandableRadio';
import AddressFields from './AddressFields';
import ContactFields from './ContactFields';
//import AddressSummary from './AddressSummary';
import {
    Typography,
    Box,
    TextField,
    Checkbox,
    FormControlLabel,
    RadioGroup,
    Avatar,
    Grid
} from '@material-ui/core';
import * as types from '../../store/constants';

const useStyles = makeStyles((theme) => ({
    stageInfo: {

        "& .MuiAvatar-root": {
            width: '20px',
            height: '20px',
            marginRight: '10px',
            color: theme.palette.getContrastText('#f3f6f6'),
            backgroundColor: '#f3f6f6',
            fontSize: '0.7em'
        },
        "&.selected .MuiAvatar-root": {
            color: theme.palette.getContrastText(theme.palette.primary.main),
            backgroundColor: theme.palette.primary.main,
        },
        "&.MuiTypography-root": {
            color: '#716994'
        },
        "&.selected .MuiTypography-root": {
            color: '#12054d'
        }
    },
    sectionHeader: {
        color: '#12054d',
        paddingBottom: '10px'
    }
}));

const Checkout = ({ quoteState, setInfo, setFormInfo }) => {

    const classes = useStyles();
    const setTermsAgreed = e => {
        setInfo({ declarationAgreedDate: e.target.checked ? new Date().toISOString() : null });
    }

    const setPaymentType = e => {
        setInfo({ paymentType: e.target.value });
    }

    //const setIsMainELContact = e => {
    //    setInfo({ isMainELContact: e.target.value === "yes" });
    //}

    //const setDifferentBillingAddress = e => {
    //    setInfo({ differentBillingAddress: e.target.value === "yes" });
    //}

    const setField = (field, e) => {
        setFormInfo({ [field]: e.target.value });
    }

    const StageInfo = ({ number, name }) => {
        let cl = classes.stageInfo;
        if (number === quoteState.subStage)
            cl += " selected";

        return (
            <Box display="flex" alignItems="center" className={cl}>
                <Avatar>{number}</Avatar>
                <Typography>{name}</Typography>
            </Box>
        );
    }

    const ddPanel = (
        <Box display="flex" flexDirection="column" gridGap="20px" style={{ width: '100%' }}>
            <Typography>Bank Details</Typography>
            <TextField
                value={quoteState.formInfo.bankAccountName || ""}
                onChange={e => setField('bankAccountName', e)}
                inputProps={{ maxLength: 200 }}
                label="Account Name"
                placeholder="Account Name"
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
            />
            <TextField
                value={quoteState.formInfo.bankSortCode || ""}
                onChange={e => setField('bankSortCode', e)}
                inputProps={{ maxLength: 25 }}
                tyle={{ width: '50%' }}
                label="Sort Code"
                placeholder="Sort Code"
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
            />
            <TextField
                value={quoteState.formInfo.bankAccountNumber || ""}
                inputProps={{ maxLength: 25 }}
                onChange={e => setField('bankAccountNumber', e)}
                style={{ width: '50%' }}
                label="Account Number"
                placeholder="Account Number"
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
            />

        </Box>
    );

    const ccPanel = (
        <Box display="flex" flexDirection="column" gridGap="20px" style={{ width: '100%' }}>
            <Typography>You will be redirected to our payment provider to collect card details.</Typography> 
        </Box>
    );

    const invoicePanel = (
        <Box display="flex" flexDirection="column" gridGap="20px" style={{ width: '100%' }}>
            <Typography>Billing Contact</Typography>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        value={quoteState.formInfo.billingFirstName || ""}
                        onChange={e => setField('billingFirstName', e)}
                        style={{ width: '100%' }}
                        label="First Name"
                        inputProps={{ maxLength: 40 }}
                        variant="outlined"
                        size="small"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        value={quoteState.formInfo.billingLastName || ""}
                        onChange={e => setField('billingLastName', e)}
                        style={{ width: '100%' }}
                        label="Last Name"
                        inputProps={{ maxLength: 80 }}
                        variant="outlined"
                        size="small"
                    />
                </Grid>
            </Grid>
            <TextField
                value={quoteState.formInfo.billingEmail || ""}
                onChange={e => setField('billingEmail', e)}
                label="Email Address"
                inputProps={{ maxLength: 80 }}
                variant="outlined"
                size="small"
            />
        </Box>
    );    

    return (
        <Box display="flex" flexDirection="column" gridGap="24px">
            <Box display="flex" justifyContent="center">
                <StageInfo number={1} name="Information" />
                <Box style={{ paddingRight: '10px', paddingLeft: '10px' }}>&#8212;</Box>
                <StageInfo number={2} name="Payment" />
            </Box>
            {quoteState.subStage === 1 &&
                <React.Fragment>
                    {false &&
                        <Box display="flex" flexDirection="column" gridGap="10px">
                            <Typography className={classes.sectionHeader} variant="h4" mb={3}>{quoteState.partnerName}</Typography>
                            <TextField
                                value={quoteState.formInfo.memberId || ""}
                                label="Membership Number"
                                variant="outlined"
                                size="small"
                                error={!quoteState.memberId?.trim()}
                                onChange={e => setField('memberId', e)}
                                inputProps={{ maxLength: 40 }}
                            />
                        </Box>
                    }
                    <Box display="flex" flexDirection="column" gridGap="10px">
                        <Typography className={classes.sectionHeader} variant="h4" mb={3}>About You</Typography>
                        <ContactFields id="mainContact"/>
                    </Box>
                    <Box display="flex" flexDirection="column" gridGap="10px">
                        <Typography className={classes.sectionHeader} variant="h4" mb={3}>About Your Company</Typography>
                        <TextField
                            value={quoteState.formInfo.accountName || ""}
                            label="Company Name"
                            variant="outlined"
                            size="small"
                            inputProps={{ maxLength: 80 }}
                            onChange={e => setField('accountName', e)}
                            error={!quoteState.formInfo.accountName?.trim()}
                        />
                        <TextField
                            value={quoteState.formInfo.registrationNumber || ""}
                            onChange={e => setField('registrationNumber', e)}
                            label="Company Registration Number"
                            variant="outlined"
                            size="small"
                            inputProps={{ maxLength: 20 }}
                        />
                    </Box>
                    <Box display="flex" flexDirection="column" gridGap="10px">
                        <Typography className={classes.sectionHeader} variant="h6" mb={3}>Company Address</Typography>
                        <AddressFields id="mainAddress" />
                    </Box>
                    {/*<Box display="flex" flexDirection="column" gridGap="10px">*/}
                    {/*    <Typography className={classes.sectionHeader} variant="h6" mb={3}>Employment Law Contact</Typography>*/}
                    {/*    <Typography>Will you be the main Employment Law Contact?</Typography>*/}
                    {/*    <RadioGroup value={(quoteState.isMainELContact ? "yes" : "no")} onChange={setIsMainELContact}>*/}
                    {/*        <ExpandableRadio*/}
                    {/*            withoutBorder*/}
                    {/*            value="yes"*/}
                    {/*            label="Yes"*/}
                    {/*            isSelected={quoteState.isMainELContact}*/}
                    {/*            maxHeight="0"*/}
                    {/*        />*/}
                    {/*        <ExpandableRadio*/}
                    {/*            withoutBorder*/}
                    {/*            value="no"*/}
                    {/*            label="No, somebody else"*/}
                    {/*            content={<ContactFields id="elContact" />}*/}
                    {/*            isSelected={!quoteState.isMainELContact}*/}
                    {/*            maxHeight="140px"*/}
                    {/*        />                                               */}
                    {/*    </RadioGroup>                        */}
                    {/*</Box>*/}
                </React.Fragment>
            }
            {quoteState.subStage === 2 &&
                <React.Fragment>
                    <Typography variant="h5">Payment Method</Typography>
                    <RadioGroup value={quoteState.paymentType} onChange={setPaymentType}>
                        {
                            quoteState.paymentOptions.includes("Direct Debit") &&
                            <ExpandableRadio
                                value="Direct Debit"
                                label="Direct Debit"
                                content={ddPanel}
                                isSelected={quoteState.paymentType === "Direct Debit"}
                                maxHeight="205px"
                            />
                        }
                        {
                            quoteState.paymentOptions.includes("Credit Card") &&
                            <ExpandableRadio
                                value="Credit Card"
                                label="Credit / Debit Card"
                                content={ccPanel}
                                isSelected={quoteState.paymentType === "Credit Card"}
                            />
                        }
                        {
                            quoteState.paymentOptions.includes("Invoice") &&
                            <ExpandableRadio
                                value="Invoice"
                                label="Invoice - (yearly, payable within 14 days)"
                                content={invoicePanel}
                                isSelected={quoteState.paymentType === "Invoice"}
                                maxHeight="150px"
                            />
                        }
                    </RadioGroup>
                    {/*<Typography variant="h5">Billing Address</Typography>*/}
                    {/*<RadioGroup value={(quoteState.differentBillingAddress ? "yes" : "no")} onChange={setDifferentBillingAddress}>*/}
                    {/*    <ExpandableRadio*/}
                    {/*        value="no"*/}
                    {/*        label={<AddressSummary address={quoteState.formInfo.mainAddress} />}*/}
                    {/*        isSelected={!quoteState.differentBillingAddress}*/}
                    {/*        maxHeight="0"*/}
                    {/*    />*/}
                    {/*    <ExpandableRadio*/}
                    {/*        value="yes"*/}
                    {/*        label="Use a different billing address"*/}
                    {/*        content={<AddressFields id="billingAddress" />}*/}
                    {/*        isSelected={quoteState.differentBillingAddress}*/}
                    {/*        maxHeight="240px"*/}
                    {/*    />*/}
                    {/*</RadioGroup>*/}
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={!!quoteState.declarationAgreedDate}
                                onChange={setTermsAgreed}
                                style={{ color: "#7a5cf0" }}
                            />
                        }
                        label={<span>I agree with declarations and T&Cs. <a href="https://wn.worknest.com/terms" rel="noopener noreferrer" target="_blank">Click to view T&Cs</a></span>}
                    />                   
                </React.Fragment>
            }
        </Box>
    );
}

const mapStateToProps = state => ({ quoteState: state.quoteReducer });

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(actionCreators, dispatch),
        setInfo: (info) => dispatch({ type: types.SET_QUOTE_INFO, info }),
        setFormInfo: (info) => dispatch({ type: types.SET_QUOTE_FIELD, info })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);

