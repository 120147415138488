import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import {
    Box,
    FormControlLabel,
    Radio
} from '@material-ui/core';


const RadioControlLabel = withStyles({
    root: {
        width: '100%',
        margin: 0,
        "& .MuiFormControlLabel-label": {
            width: '100%'
        }
    }
})(FormControlLabel)

const ExpandableRadio = ({ label, content, value, isSelected, maxHeight, withoutBorder }) => {
    const useStyles = makeStyles(() => ({
        radioYes: {
            border: withoutBorder ? undefined : '1px solid #5a43c6',
            borderRadius: '2px',
            padding: withoutBorder ? '0px 8x' : '8px',
            '& .radioContent': {
                maxHeight: `${maxHeight}`,
                transition: 'max-height 1s',
                padding: '5px'
            }
        },
        radioNo: {
            border: withoutBorder ? undefined : '1px solid #e3e1ea',
            borderRadius: '2px',
            padding: withoutBorder ? '0px 8x' : '8px',
            color: '#757575',
            '&:hover': {
                backgroundColor: '#f6f5f8'
            },
            '& .radioContent': {
                maxHeight: 0,
                transition: 'max-height 1s',
                padding: 0
            }
        }
    }));

    const classes = useStyles();    

    return (
        <Box display="flex" className={isSelected ? classes.radioYes : classes.radioNo} alignItems="center" flexDirection="column">
            <RadioControlLabel control={<Radio color="primary" value={value} />} label={label} />
            {content && <Box className="radioContent" style={{width: '100%', overflow:'hidden'}}>{content}</Box>}
        </Box>
    );
}

export default ExpandableRadio;
