import React from 'react';
import { connect } from 'react-redux';
import {
    Typography,
    Box,
    Container,
    Button
} from '@material-ui/core'

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const ConfirmSaved = ({ quoteReference }) => {    
   
    return (
        <Container>
            <Box display="flex" textAlign="center" flexDirection="column" style={{ margin: '0 auto', minHeight: 'calc(100vh - 70px)', maxWidth: '300px'}} gridGap="5px">
                <Box display="flex" flexDirection="column" flexGrow={1} gridGap="15px" alignItems="center" justifyContent="center">
                    <CheckCircleOutlineIcon style={{ color: '#7a5cf0', backgroundColor: '#f2edff', padding: '15px', width: '60px', height: '60px', borderRadius: '30px' }} />
                    <Typography variant="h6">Quote Saved Successfully</Typography>
                    <Typography>Quote Reference: {quoteReference}</Typography>
                    <Typography>A summary of your quote has been sent to your email address</Typography>
                </Box>
                <Button variant="contained" size="large" color="primary" href={window.location.href}>View Quote</Button>
                <Button variant="contained" size="large" color="secondary" href="https://worknest.com">Return Home</Button>               
            </Box>
        </Container>       
    );
}

const mapStateToProps = state => ({ quoteReference: state.quoteReducer.name });

export default connect(mapStateToProps)(ConfirmSaved);