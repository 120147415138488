import axios from 'axios';
import store from '../store';
import * as types from '../store/constants';

function errorResponseHandler(error) {
    console.log(JSON.stringify(error));
    // check for errorHandle config
    if (error.config.hasOwnProperty('errorHandle') && error.config.errorHandle === false) {
        return Promise.reject(error);
    }

    // if has response show the error
    if (error.response?.status === 500) {
        store.dispatch({
            type: types.SET_SNACK,
            snack: {
                message: error.response.data.title,
                severity: 'error'
            }
        });
        return Promise.reject(error);
    }

    if (error.response?.status === 401) {
        store.dispatch({
            type: types.SET_SNACK,
            snack: {
                message: "Unauthorised",
                severity: 'error'
            }
        });
        return Promise.reject(error);
    }

    store.dispatch({
        type: types.SET_SNACK,
        snack: {
            message: error.message,
            severity: 'error'
        }
    });
    return Promise.reject(error);
}


const _axios = axios.create();

_axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
_axios.interceptors.response.use(
    response => response,
    errorResponseHandler
);

export default _axios;