import axios from "../plugins/axios";
import * as types from "./constants";

const initialState = {
  identifier: "",
  declaration: null,
  isLoading: false,
  isSaving: false,
  isComplete: false,
};

const apiUrl = process.env.REACT_APP_FORM_API_URL;

export const actionCreators = {
  requestDeclarationDetails: (identifier) => async (dispatch, getState) => {
    if (identifier === getState().declarationReducer.identifier) {
      return;
    }

    dispatch({ type: types.REQUEST_DECLARATION, identifier });

    axios
      .get(`${apiUrl}/insurancedeclaration/${identifier}`)
      .then((response) => {
        dispatch({
          type: types.RECEIVE_DECLARATION,
          identifier,
          details: response.data,
        });
      });
  },

  updateDeclarationDetail: (declaration) => async (dispatch, getState) => {
    dispatch({ type: types.UPDATE_DECLARATION, declaration });
  },

  saveChanges: () => async (dispatch, getState) => {
    dispatch({ type: types.LEI_SAVE_CHANGES });

    const page = getState().declarationReducer;
    const identifier = page.identifier;
    const declaration = page.declaration;
    axios
      .post(`${apiUrl}/insurancedeclaration/${identifier}`, declaration)
      .then((response) => {
        dispatch({ type: types.LEI_SAVED_CHANGES, isComplete: true });
      })
      .catch((err) => {
        dispatch({ type: types.LEI_SAVED_CHANGES, isComplete: false });
      });
  },
};

const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === types.REQUEST_DECLARATION) {
    return {
      ...state,
      identifier: action.identifier,
      isLoading: true,
    };
  }

  if (action.type === types.RECEIVE_DECLARATION) {
    let completed = false;
    if (action.details && action.details.Completed__c) {
      completed = true;
    }
    return {
      ...state,
      identifier: action.identifier,
      declaration: action.details,
      isLoading: false,
      isComplete: completed,
    };
  }

  if (action.type === types.UPDATE_DECLARATION) {
    return {
      ...state,
      declaration: action.declaration,
      isLoading: false,
    };
  }

  if (action.type === types.LEI_SAVE_CHANGES) {
    return {
      ...state,
      isSaving: true,
    };
  }

  if (action.type === types.LEI_SAVED_CHANGES) {
    return {
      ...state,
      isSaving: false,
      isComplete: action.isComplete,
    };
  }

  return state;
};

export default reducer;
