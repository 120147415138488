import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';

if(process.env.NODE_ENV !== "development")
  console.log(`Build ${process.env.REACT_APP_BUILD_NUMBER} (${process.env.REACT_APP_COMMIT_HASH})`)

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root'));
