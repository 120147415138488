import { makeStyles } from '@material-ui/core/styles';

const workNestStyles = makeStyles((theme) => ({
    panel: {
        background: 'white',
        borderRadius: '3px',
        padding: '24px',
        [theme.breakpoints.up('sm')]: {
            border: '1px solid #b8b4ca',
            marginBottom: '24px'
        },
        [theme.breakpoints.only('xs')]: {

        }
    },
    grid: {
        [theme.breakpoints.up('sm')]: {
            paddingTop: '20px'
        },
        [theme.breakpoints.only('xs')]: {
            paddingTop: 0
        }
    }
}));


export default workNestStyles;
