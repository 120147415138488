import React from 'react';
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import CustomRadio from './CustomRadio';

import {
    Typography,
    Box,
    RadioGroup,
    Divider as MuiDivider
} from '@material-ui/core';

const Divider = styled(MuiDivider)(spacing);

const ProductSection = ({ product, setSelected }) => {

    let feeInfo;
    let feeLower;

    if (product.free)
        feeLower = "Free for the duration of your contract";

    if (product.yearOneFree)
        feeLower = "First year free";

    if (product.yearOneFree || product.free)
        feeInfo = <del><strong>&pound;{(product.price * -1).toFixed(2)}</strong>/year</del>;
    else
        feeInfo = <span><strong>&pound;{(product.price).toFixed(2)}</strong>{product.pricingMethod !== "List" && "/year"}</span>;

    return (
        <Box display="flex" flexDirection="column">
            <Typography variant="h6" style={{ color: '#12054d'}}>{product.name}</Typography>
            <Box mb={3} style={{ color: '#12054d' }}><Typography>{product.description}</Typography></Box>
            <Box mb={3}>
                <div style={{ fontSize: '0.8em' }} dangerouslySetInnerHTML={{ __html: product.summary }}></div>
            </Box>
            <RadioGroup value={(product.selected ? "yes" : "no")} onChange={setSelected}>                
                <CustomRadio right={feeInfo} main={product.name} value="yes" selected={product.selected} lowerRight={feeLower} />
                {!product.required && <Box mt={1}><CustomRadio main="No thanks" value="no" selected={product.selected} /></Box>}
            </RadioGroup>
            <Divider my={2} />
        </Box>
    );
}

export default ProductSection;
