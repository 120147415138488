import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { SET_QUOTE_FIELD } from '../../store/constants';

import {
    TextField,
    Box
} from '@material-ui/core';

const AddressFields = ({ id, quoteState, setFormInfo }) => {

    const address = quoteState.formInfo[id];    
    
    const setField = e => {

        let updated = {
            ...address,
            [e.target.name]: e.target.value
        };       

        setFormInfo({
            [id]: updated
        });
    }

    useEffect(() => {
        console.log('address use effect');
        setFormInfo({
            [id]: {
                ...address,
                isValid: (address.addressLine1?.trim() && address.city?.trim() && address.postCode?.trim()) ? true : false
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address.addressLine1, address.city, address.postCode, id, setFormInfo]);

    return (
        <Box display="flex" flexDirection="column" gridGap="10px">
            <TextField
                value={address.addressLine1 || ""}
                onChange={setField}
                name="addressLine1"
                label="Address 1"
                variant="outlined"
                size="small"
                inputProps={{ maxLength: 100 }}
                error={!address.addressLine1?.trim()}
            />
            <TextField
                value={address.addressLine2 || ""}
                label="Address 2"
                name="addressLine2"
                inputProps={{ maxLength: 50 }}
                onChange={setField}
                variant="outlined"
                size="small"
            />
            <TextField
                value={address.addressLine3 || ""}
                label="Address 3"
                name="addressLine3"
                inputProps={{ maxLength: 50 }}
                onChange={setField}
                variant="outlined"
                size="small"
            />
            <TextField
                value={address.city || ""}
                onChange={setField}
                name="city"
                label="City"
                variant="outlined"
                error={!address.city?.trim()}
                inputProps={{ maxLength: 40 }}
                size="small"
            />
            <TextField
                value={address.postCode || ""}
                onChange={setField}
                name="postCode"
                label="Postcode"
                inputProps={{ maxLength: 20 }}
                variant="outlined"
                size="small"
                error={!address.postCode?.trim()}
            />
        </Box>
    );
}
const mapStateToProps = state => ({ quoteState: state.quoteReducer });

const mapDispatchToProps = dispatch => {
    return {
        setFormInfo: (info) => dispatch({ type: SET_QUOTE_FIELD, info })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressFields);

