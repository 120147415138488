import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import * as types from '../../store/constants';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/quoteReducer';
import { useHistory } from "react-router-dom";

import {
    Typography,
    Box,
    Button as MuiButton
} from '@material-ui/core';

const Button = withStyles({
    root: {
        lineHeight: '40px'
    }
})(MuiButton)

const QuoteStageButtons = ({ quoteState, setInfo, saveQuote, completeOrder }) => {

    const history = useHistory();

    const setStage = (stage, subStage) => {
        setInfo({ stage, subStage });
    }

    const isValidCheckout = useMemo(() => {
        if (!quoteState.declarationAgreedDate)
            return false;        

        if (quoteState.paymentType === "Credit Card") {
            return true;
        }

        if (quoteState.paymentType === "Direct Debit") {
            return quoteState.formInfo.bankAccountName && quoteState.formInfo.bankSortCode && quoteState.formInfo.bankAccountNumber;
        }

        if (quoteState.paymentType === "Invoice") {
            return quoteState.formInfo.billingFirstName && quoteState.formInfo.billingLastName && quoteState.formInfo.billingEmail;
        }

        return false;
    }, [quoteState]);

    const validForm = quoteState.formInfo.mainContact?.isValid
        && (quoteState.isMainELContact || quoteState.formInfo.elContact?.isValid)
        && quoteState.formInfo.mainAddress?.isValid
        && quoteState.formInfo.accountName?.trim()
        && (!quoteState.differentBillingAddress || quoteState.formInfo.billingAddress?.isValid);

    return (
        <Box display="flex" flexDirection="column" gridGap="10px" style={{ marginTop: '15px' }}>
            {
                quoteState.stage === "quote" &&
                <Button variant="contained" size="large" color="primary" onClick={() => setStage('checkout', 1)}>Proceed to Checkout</Button>
            }
            {
                false && quoteState.stage === "quote" &&
                <React.Fragment>
                    <Box mt={5}>

                    </Box>
                    <Box flexGrow={1}></Box>
                    <Box display="flex" flexDirection="column" style={{ border: '1px solid #e3e3e3', padding: '20px' }}>
                        <Box mb={2}><Typography><strong>Save quote and return later</strong></Typography></Box>
                        <Button onClick={saveQuote} variant="contained" size="large" color="inherit" style={{ textTransform: 'none', width: '100%' }}>Save Quote</Button>
                    </Box>
                </React.Fragment>
            }
            {
                quoteState.stage === "checkout" && quoteState.subStage === 1 &&
                <Button variant="contained" size="large" disabled={!validForm} color="primary" onClick={() => setStage('checkout', 2)}>Continue to Payment</Button>
            }
            {
                quoteState.stage === "checkout" &&
                <Button variant="contained" size="large" color="secondary" onClick={() => setStage('quote')}>Return to Quote</Button>
            }
            {
                quoteState.stage === "checkout" && quoteState.subStage === 2 && isValidCheckout && validForm &&
                <Button variant="contained" size="large" color="primary" onClick={() => completeOrder(history)}>Complete Order</Button>
            }
        </Box>
    );
}

const mapStateToProps = state => ({ quoteState: state.quoteReducer });

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(actionCreators, dispatch),
        setInfo: (info) => dispatch({ type: types.SET_QUOTE_INFO, info })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuoteStageButtons);

