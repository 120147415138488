import * as types from './constants';

const initialState = {
    snack: {},
    open: false
};

const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {

        case (types.SET_SNACK): {
            return {
                ...state,
                snack: action.snack,
                open: true
            };
        }

        case (types.REMOVE_SNACK): {
            return {
                ...state,
                snack: {},
                open: false
            };
        }

        default:
            return state;
    }
};

export default reducer;