import React from 'react';
import worknestSmall from '../../images/WorkNestLogo.svg';
import { withStyles } from '@material-ui/core/styles';
import {
    LinearProgress as MuiLinearProgress,
    Typography,
    Box,
    Container
} from '@material-ui/core'

const LinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: '#f2edff'
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#7a5cf0',
    },
}))(MuiLinearProgress);

const Waiting = ({ message, still }) => {    
   
    return (
        <Container>
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" minHeight="calc(100vh - 70px)" gridGap="15px">
                <img style={{ width: '20px' }} src={worknestSmall} alt="WorkNest logo"/>
                <LinearProgress style={{ width: '200px' }} variant={(still ? "determinate" : "indeterminate")} value={100} />
                <Typography>{message}</Typography>
            </Box>
        </Container>       
    );
}

export default Waiting;
