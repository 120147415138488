import * as React from 'react';
import { connect } from 'react-redux';

const Home = () => (
  <div>
    <h1>WorkNest Ltd Client Form site</h1>
    <p>Pages from within this site require a link with a unique identifier.</p>    
  </div>
);

export default connect()(Home);
