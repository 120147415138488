import React from 'react';
import { Box, Typography } from '@material-ui/core';

const AddressSummary = ({ address }) => {

    return (
        <Box display="flex" flexDirection="column">
            {address.addressLine1 && <Typography>{address.addressLine1}</Typography>}
            {address.addressLine2 && <Typography>{address.addressLine2}</Typography>}
            {address.addressLine3 && <Typography>{address.addressLine3}</Typography>}
            {address.city && <Typography>{address.city}</Typography>}
            {address.postCode && <Typography>{address.postCode}</Typography>}
        </Box>
    );    
}

export default AddressSummary;

