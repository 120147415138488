import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import {
    quote as quoteRoutes,
    general as generalRoutes
} from "./index";
import GeneralLayout from "../layout/Layout";
import QuoteLayout from "../layout/QuoteLayout";
import { Box } from "@material-ui/core";
import history from "./history";

const childRoutes = (Layout, routes) => {    

    return routes.map(({ children, path, component: Component }, index) =>
        children ? (
            // Route item with children
            children.map(({ path, component: Component }, index) => (
                <Route
                    key={index}
                    path={path}
                    exact
                    render={props => (
                        <Layout>
                            <Component {...props} />
                        </Layout>
                    )}
                />
            ))
        ) : (
            // Route item without children
            <Route
                key={index}
                path={path}
                exact
                render={props => (
                    <Layout>
                        <Component {...props} />
                    </Layout>
                )}
            />
        )
    );
}    

const Routes = () => (

    <Box display="flex">
        <Router history={history}>
            <Box flexGrow={1}>
                <Switch>
                    {childRoutes(GeneralLayout, generalRoutes)}
                    {childRoutes(QuoteLayout, quoteRoutes)}
                </Switch>
            </Box>         
        </Router>
    </Box>
);

export default Routes;
